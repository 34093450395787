<template>
  <v-container fluid class="pt-0 fill-height">
    <MainModal
        :main="{ component: 'CloseSwapDebt', title: 'Створити закриття/перенесення боргу' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected)"
        :modal="show_dialog"
        @updateItemModal="updateItemModal"
        @crud="modalCrud"
    />
    <MainModal
        :main="{ component: 'CloseSwapDebtLegacy', title: 'Створити закриття/перенесення боргу' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_legacy)"
        :modal="show_legacy_dialog"
        @updateItemModal="updateLegacyItemModal"
        @crud="modalCrud"
    />
    <v-row justify="center" style="height: 100%; display: flex; flex-direction: column; flex-wrap: nowrap">
      <v-col cols="12" style="flex: 0">
        <v-card>
          <v-toolbar tile elevation="0" outlined dense style="border: none"
                     :height="$vuetify.breakpoint.xs ? 90 : 54">
            <template v-if="$vuetify.breakpoint.smAndUp">
              <v-toolbar-title class="font-weight-medium" style="font-size: 1.1rem">Перелік закритих/перенесених боргів за
                {{ current_month | formatDate('MMMM YYYY') }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items class="shrink">
                <v-row class="align-center">
                  <MonthComponent
                          v-model="current_month" :class_="'mr-2'" :label="`Місяць`"
                          @onMonthChange="onMonthChange"
                  />
                  <v-tooltip bottom color="success" v-if="!legacy">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn @click="openCreateDialog"
                             v-bind="attrs"
                             v-on="on"
                             class="grey lighten-4 mr-2"
                             depressed
                      >
                        <v-icon class="mr-2" left>mdi-plus</v-icon>
                        Створити
                      </v-btn>
                    </template>
                    <span>Створити закриття/перенесення боргів</span>
                  </v-tooltip>
                  <v-tooltip bottom color="success" v-if="legacy">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn @click="openCreateLegacyDialog"
                             v-bind="attrs"
                             v-on="on"
                             class="grey lighten-4 mr-2"
                             depressed
                      >
                        <v-icon class="mr-2" left>mdi-plus</v-icon>
                        Створити
                      </v-btn>
                    </template>
                    <span>Створити закриття/перенесення боргів</span>
                  </v-tooltip>
                </v-row>
              </v-toolbar-items>
            </template>
            <template v-else>
              <div style="width: 100%">
                <div class="d-flex mb-2">
                  <v-toolbar-title class="font-weight-medium" style="font-size: 1.3rem">
                    Перелік закритих/перенесених боргів за за {{ current_month | formatDate('MMMM YYYY') }} року
                  </v-toolbar-title>
                </div>
                <div class="d-flex">
                  <MonthComponent v-model="current_month" :class_="'mr-2'" :label="`Місяць`"/>
                  <v-spacer></v-spacer>
                  <v-btn depressed small @click.stop="" height="36">
                    <v-icon color="grey darken-2">
                      mdi-plus
                    </v-icon>
                    Створити
                  </v-btn>
                </div>
              </div>
            </template>
          </v-toolbar>
        </v-card>
      </v-col>
      <v-col cols="12" style="flex: 1">
        <template v-if="items.length">
          <v-card
              v-for="(item, idx) in items" :key="idx" tile elevation="2"
              class="mb-2 d-flex flex-wrap flex-row row-card"
              style="border-left: 3px solid #5bb55f"
              @click.stop="onItemClick(item)"
          >
            <v-col cols="12" md="2" :class="$vuetify.breakpoint.xs ? 'pb-0' : ''">
              <div>Дата створення</div>
              <div>{{ item.create_date | formatDate('DD.MM.YYYY HH:mm:ss') }}</div>
            </v-col>
            <v-col cols="12" md="3" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
              <div>Послуга (для закриття)</div>
              <div class="d-flex">
                <div>
                  <v-icon size="20"
                          class="mr-2" :color="getAccountIcon(item.service_to_close_icon, 'color')">
                    {{ getAccountIcon(item.service_to_close_icon, 'icon') }}
                  </v-icon>
                </div>
                <div class="font-weight-medium">
                  {{ item.service_to_close_name }}
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="3" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
              <div>Послуга (для перенесення)</div>
              <div class="d-flex">
                <div>
                  <v-icon size="20"
                          class="mr-2" :color="getAccountIcon(item.service_to_swap_icon, 'color')">
                    {{ getAccountIcon(item.service_to_swap_icon, 'icon') }}
                  </v-icon>
                </div>
                <div class="font-weight-medium">
                  {{ item.service_to_swap_name }}
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="2" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
              <div>Всього / К-сть</div>
              <div>
                <span>{{ item.total_sum | formatToFixed }}</span> / <span>{{ item.total_row | formatNumber }}</span>
              </div>
            </v-col>
            <v-col cols="12" md="2" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
              <div>Коментар</div>
              <div>{{ item.comment ? item.comment : 'Коментар відсутній' }}</div>
            </v-col>
          </v-card>
        </template>
        <template v-else>
          <v-card style="height: 100%; display: flex; align-items: center; justify-content: center">
            <div class="wrapper align-center text-center">
              <v-icon size="200" color="success" style="opacity: .24">mdi-clock</v-icon>
              <div class="title grey--text text--darken-2 px-4">Документи для відображення відсутні <br> Спершу створіть
                документ
              </div>
            </div>
          </v-card>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import {GET_CURRENT_MONTH, GET_CURRENT_LEGACY_MONTH} from "@/store/actions/work_month";
import closeSwapDebtAPI from "@/utils/axios/close_swap_debt"
import {getAccountIcon} from "@/utils/icons";

export default {
  name: "OneTimeCharge",
  components: {
    MonthComponent: () => import("@/components/MonthComponent"),
    MainModal: () => import("@/components/modal/MainModal")
  },
  computed: {
    ...mapGetters({
      legacy: 'isLegacy'
    })
  },
  data() {
    return {
      current_month: null,
      items: [],
      selected: {},
      selected_legacy: {},
      show_dialog: false,
      show_legacy_dialog: false
    }
  },
  methods: {
    onMonthChange(payload) {
        if (payload) {
            const payload = {
                month: this.current_month,
                legacy: this.legacy
            }
            closeSwapDebtAPI.all(payload)
                .then(response => response.data)
                .then(data => {
                    this.items = data
                })
        }
    },
    modalCrud(payload) {
      if (payload) {
        if (payload.method === 'create') {
          this.items.push(payload)
        }
        if (payload.method === 'update') {
          const item = this.items.find(item => item.id === payload.id)
          Object.keys(payload).forEach(key => {
            item[key] = payload[key]
          })
        }
        if (payload.method === 'delete') {
          const item = this.items.find(item => item.id === payload.id)
          if (item) {
            this.items.splice(this.items.indexOf(item), 1)
          }
        }
      }
    },
    getAccountIcon,
    updateItemModal() {
      this.show_dialog = false
      this.selected = {}
    },
    updateLegacyItemModal() {
      this.show_dialog = false
      this.selected_legacy = {}
    },
    openCreateDialog() {
      this.selected = {
        month: this.current_month
      }
      this.show_dialog = true
    },
    openCreateLegacyDialog() {
      this.selected_legacy = {
        month: this.current_month,
        main_contract: true
      }
      this.show_legacy_dialog = true
    },
    onItemClick(payload) {
      this.selected = {}
      this.selected_legacy = {}
      this.show_dialog = false
      this.show_legacy_dialog = false

      const local_payload = JSON.parse(JSON.stringify(payload))
      local_payload.month = this.current_month

      if (this.legacy) {
        this.selected_legacy = local_payload
        this.show_legacy_dialog = true
      } else {
        this.selected = local_payload
        this.show_dialog = true
      }

    },
  },
  created() {
    const d = this.legacy ? GET_CURRENT_LEGACY_MONTH : GET_CURRENT_MONTH
    this.$store.dispatch(d)
        .then(finish => {
          if (finish) {
            this.current_month = this.legacy ? this.$store.getters.getCurrentMonthLegacy : this.$store.getters.getCurrentMonth
            const payload = {
              month: this.current_month,
              legacy: this.legacy
            }
            closeSwapDebtAPI.all(payload)
                .then(response => response.data)
                .then(data => {
                  this.items = data
                })
          }
        })
  }
}
</script>

<style scoped lang="scss">
.row-card {
  div > div:nth-child(1) {
    font-size: .68rem;
    font-weight: 400;
    color: #757575
  }

  div > div:nth-child(2) {
    font-size: .84rem;
    color: #4a4a4a;

    span:nth-child(1) {
      font-weight: 500;
      color: #2a9d2f;
    }

    span:nth-child(2) {
      font-weight: 500;
      color: #2d2d2d;
    }
  }
}
</style>